<template>
  <div class="signature">
    <div class="signature-header">
      <div class="signature-header-boxLeft">
        
        <div class="signature-header-boxLeft__title">
          <TitlePage :title="$t('combos.texts.title')">
            <IconSax name="box-tick" />
          </TitlePage>
        </div>
        <div class="signature-header-boxLeft__description">
          {{ $t("combos.texts.txt1") }}
        </div>
      </div>
      <div class="signature-header-boxRight">
        <ButtonPlay
          type="normal"
          size="lg"
          min-width="177px"
          @click="openModalNew"
        >
        {{ $t("combos.texts.txt2") }}
        </ButtonPlay>
      </div>
    </div>
    <div class="signature-body">
      <b-table
        class="signature-body-table"
        :fields="fields"
        show-empty
        small
        stacked="md"
        :items="allCombos"
        thead-tr-class="signature-body-table-header"
        tbody-tr-class="signature-body-table-row"
        :filter-included-fields="filterOn"
      >
        <template #empty>
          <p class="title text-center-empty">{{ emptyText }}</p>
        </template>
        <template #emptyfiltered>
          <p class="title text-center-empty">{{ emptyText }}</p>
        </template>

        <template #cell(id)="row">
          <div class="signature-body-table-row__info">{{ row.item.id }}</div>
        </template>

        <template #cell(title)="row">
          <div class="signature-body-table-row__info">{{ row.item.title }}</div>
        </template>

        <template #cell(created_at)="row">
          <div class="signature-body-table-row__info">
            {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
          </div>
        </template>

        <template #cell(updated_at)="row">
          <div class="signature-body-table-row__info">
            {{ row.item.updated_at | moment("DD/MM/YYYY HH:mm") }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="signature-body-table-row__actions">
            <div
              class="signature-body-table-row__actions--manager"
              @click="editAssinatura(row.item)"
            >
            {{ $t("combos.texts.txt3") }}
            </div>
            <div
              class="signature-body-table-row__actions--delete"
              @click="deleteSignature(row.item.id)"
            >
            {{ $t("combos.texts.txt4") }}
            </div>
          </div>
        </template>
      </b-table>
      <div class="signature-body-paginate">
        <div class="text" @click="toPage('prev')">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 6H1M1 6L6 11M1 6L6 1"
              stroke="#333333"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t("combos.texts.prev") }}
        </div>
        <div class="text" @click="toPage('next')">
          {{ $t("combos.texts.next") }}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6L11 6M11 6L6 1M11 6L6 11"
              stroke="#333333"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="signature-modal">
      <b-modal
        id="modal-new-signature"
        :title="this.editMode ? $t('combos.texts.txt5') : $t('combos.texts.txt6') "
        hide-footer
        size="lg"
      >
        <div class="spaceModal">
          <div class="spaceSelectPainel">
            <div class="spacePainels">
              <div class="spaceInputs">
                <b-form-group :label="$t('combos.texts.txt7')" label-for="name">
                  <div class="d-flex align-items-center gap-1">
                    <b-form-input
                      v-model="nameSignature"
                      :placeholder="$t('combos.texts.txt7')"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>

              <div class="spaceInputs2">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="name" class="d-block" id="__BVID__69__BV_label_"
                      >{{ $t('combos.texts.txt8') }}</label
                    >
                    <button
                      class="select-all"
                      @click="selectAllCourses()"
                    >
                      {{ getTextLabel() }}
                    </button>
                  </div>

                  <multiselect
                    :placeholder="$t('combos.texts.txt9') "
                    selectedLabel=""
                    :deselectLabel="$t('combos.texts.txt10')"
                    tagPlaceholder=""
                    :close-on-select="false"
                    :selectLabel="$t('combos.texts.txt10')"
                    track-by="id"
                    :multiple="true"
                    :taggable="false"
                    :custom-label="customLabelActionMail"
                    v-model="actionCoursesSelected"
                    :options="allCourses"
                  >
                    <template slot="afterList">
                      <div v-observe-visibility="reachedEndOfList" />
                    </template>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <div class="saveSignature d-flex justify-content-end">
              <ButtonPlay
                type="normal"
                size="lg"
                min-width="177px"
                @click="saveSignature"
              >
                {{ this.editMode ? this.$t('combos.texts.txt11') : this.$t('combos.texts.txt12') }}
              </ButtonPlay>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .form-control {
  height: 48px;
}

::v-deep .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--opposite-color);
}

::v-deep .multiselect__tags {
  height: 48px !important;
  min-height: 48px;
  padding: 12px 40px 12px 12px !important;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  overflow: auto;
  border: 1px solid #ced4da !important;
}
::v-deep .modal-content .modal-header {
  border-bottom: 0px solid #ededf0;
}

.spacePainels {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.spaceSelectPainel {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.spaceInputs2.hideOpacity {
  opacity: 0.3;
  cursor: not-allowed;
}

::v-deep .table {
  min-height: 380px;
}

::v-deep .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
}

::v-deep .table th,
.table td {
  padding: 0.25rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
}

::v-deep .table td {
  padding-bottom: 15px;
  padding-top: 15px;
}

::v-deep .table td {
  padding-bottom: 10px;
}
.select-all {
  cursor: pointer;
  text-decoration: underline;
  color: var(--maincolor);
  background: none;
  border: none;
}
.signature {
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 70px var(--spacing-36);
  }

  &-header {
    @include flex-column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 70px;
    gap: 15px;

    /* Desktop */
    @media screen and (min-width: 768px) {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
    }

    &-boxLeft {
      @include flex-column;
      gap: 15px;

      /* Desktop */
      @media screen and (min-width: 768px) {
        flex-basis: 485px;
      }

      &__title {
        @extend .headline-semibold-24;
        color: var(--opposite-color);
      }
      &__description {
        @extend .body-regular-16;
        color: var(--neutral-gray-400);
      }
    }

    &-boxRight {
    }
  }

  &-body {
    &-table {
      ::v-deep &-header {
        color: var(--opposite-color);
      }
      ::v-deep &-row {
        color: var(--opposite-color);

        &__actions {
          display: flex;
          gap: 15px;

          &--delete,
          &--manager {
            font-weight: 600;
            cursor: pointer;
            color: var(--maincolor);
          }

          &--delete {
            color: var(--dangercolor);
          }
        }
      }
    }
    &-paginate {
      display: flex;
      justify-content: space-around;
      color: var(--opposite-color);

      svg path {
        stroke: var(--opposite-color);
      }
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";

const serviceMeta = MetaService.build();
import MetaService from "@/services/resources/MetaService";

const serviceSignature = SignatureService.build();
import SignatureService from "@/services/resources/SignatureService";

const serviceCourse = CourseService.build();
import CourseService from "@/services/resources/CourseService";

import notify from "@/services/libs/notificacao";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import traducao_unlayer from "../services/libs/traducao_unlayer.json";
import white from "../assets/unlayer/sample.json";

import TitlePage from "@/components/common/TitlePage.vue";

export default {
  components: {
    Multiselect,
    TitlePage
  },
  data() {
    return {
      editMode: false,
      loading: false,
      moderation: true,
      rowClass: "signature-body-table-row",
      emptyText: this.$t('combos.texts.txt13'), 
      filterOn: [],
      fields: [
        { key: "title", label: this.$t('combos.texts.txt14') },
        { key: "created_at", label: this.$t('combos.texts.txt15') },
        { key: "updated_at", label: this.$t('combos.texts.txt16') },
        { key: "action", label: this.$t('combos.texts.txt17') },
      ],
      pageActual: 1,
      lastPage: 1,
      firstPage: 1,
      searchComment: "",
      nameSignature: "",
      client: {
        width: 0,
      },
      idToEdit: null,
      allCombos: [],
      allCourses: [],
      actionCoursesSelected: [],
      pageCourse: 1,
      lastPageCourse: 1,
      selectedStatus: "published",
      optionsStatus: [
        { value: "published", text: this.$t('combos.texts.txt18') },
        { value: "draft", text: this.$t('combos.texts.txt19') },
      ],
      designTags: {
        business_name: "G Digital",
        current_user_name: "G Digital",
        link_botao: "https://google.com.br",
      },
      options_unlayer: {
        mergeTags: [
          { name: this.$t('combos.texts.txt20'), value: "{{nome}}" },
          { name: this.$t('combos.texts.txt21'), value: "{{primeiro_nome}}" },
          { name: this.$t('combos.texts.txt22'), value: "{{lead}}" },
        ],
        features: {
          textEditor: {
            cleanPaste: false,
          },
        },
        designTags: {
          business_name: "",
          current_user_name: "",
          link_botao: "https://google.com.br",
        },
      },
      traducaoUnlayer: traducao_unlayer,
    };
  },
  computed: {
    ...mapGetters({
      getIsMobile: "layout/getIsMobile",
    }),
  },
  methods: {
    selectAllCourses() {
      if(this.actionCoursesSelected.length == this.allCourses.length){
        this.actionCoursesSelected = [];
        return;
      }
      this.actionCoursesSelected = this.allCourses;
    },
    getTextLabel() {
      if(this.actionCoursesSelected.length !== this.allCourses.length || this.actionCoursesSelected.length == 0){
        return this.$t('combos.texts.txt23');
      }else{
        return this.$t('combos.texts.txt24');
      }
    },
    salvarEmail() {
      this.$refs.emailEditor2.editor.exportHtml((data) => {
        //console.log('exportHtml', data);
        var urlDesign = "welcomeSignatureEmailDesign";
        var urlHtml = "welcomeSignatureEmailHtml";

        var dataDesign = {
          id: urlDesign,
          value: JSON.stringify(data.design),
        };
        //console.log(data);
        this.loading = true;
        serviceMeta
          .postID(dataDesign)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });

        var dataHtml = {
          id: urlHtml,
          value: data.html,
        };
        //console.log(data);
        this.loading = true;
        serviceMeta
          .postID(dataHtml)
          .then(() => {
            this.$root.$emit("bv::hide::modal", "modal-mailass", "#btnShow");
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    cancelaEmail() {
      this.$root.$emit("bv::hide::modal", "modal-mailass", "#btnShow");
    },
    openConfigMail() {
      this.$root.$emit("bv::show::modal", "modal-mailass", "#btnShow");
      this.loading = true;
      var keyUrl = "keys[]=welcomeSignatureEmailDesign";
      serviceMeta
        .search(keyUrl)
        .then((resp) => {
          this.$refs.emailEditor2.editor.loadDesign(
            JSON.parse(resp.welcomeSignatureEmailDesign)
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    designUpdated() {
      this.$refs.emailEditor2.editor.exportHtml((data) => {
        //console.log('exportHtml', data);
      });
    },
    addTemplateInitial() {
      this.template_selected = white;
    },
    editorLoaded() {
      let email = this.template_selected;
      this.$refs.emailEditor2.editor.loadDesign(email);
    },
    exportHtml() {
      this.$refs.emailEditor2.editor.exportHtml((data) => {
        //console.log('exportHtml', data);
      });
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.addNextPageCourse();
      }
    },
    addNextPageCourse() {
      if (this.lastPageCourse >= this.pageCourse) {
        this.pageCourse = this.pageCourse + 1;
        this.getAllCourses("push");
      }
    },
    async saveSignature() {
      if (!this.editMode) {
        await serviceSignature
          .create({ title: this.nameSignature })
          .then((resp) => {
            setTimeout(async () => {
              await this.removeCourse(resp.id);
              await this.addCourse(resp.id);
              await this.getAllCombos();
              await this.getAllCourses();
              this.$root.$emit(
                "bv::hide::modal",
                "modal-new-signature",
                "#btnShow"
              );
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            // this.idToEdit = null;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await serviceSignature
          .postID({
            id: this.idToEdit,
            title: this.nameSignature,
            status: this.selectedStatus,
          })
          .then(async () => {
            const id = this.idToEdit;
            await this.removeCourse(id);
            await this.addCourse(id);
            await this.getAllCombos();
            await this.getAllCourses();
            this.$root.$emit(
              "bv::hide::modal",
              "modal-new-signature",
              "#btnShow"
            );
            notify("sucesso", this.$t('combos.texts.txt25'));
          });
      }
    },
    async addCourse(id) {
      const selectedCourse = this.actionCoursesSelected.map(
        (course) => course.id
      );

      const data = {
        id: id + "/course/",
        course_ids: [...selectedCourse],
      };

      if (selectedCourse.length > 0) {
        await serviceSignature
          .postID(data)
          .then((resp) => {
            // this.getCoursesToAssing(id);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    async removeCourse(id) {
      this.loading = true;

      const idsSelecionados = this.actionCoursesSelected.map(
        (course) => course.id
      );

      const todosIds = this.allCourses.map((course) => course.id);

      function encontrarNaoSelecionados(idsSelecionados, todosIds) {
        return todosIds.filter((id) => !idsSelecionados.includes(id));
      }

      const idsNaoSelecionados = encontrarNaoSelecionados(
        idsSelecionados,
        todosIds
      );

      let data = {
        id: id + "/course/delete",
        course_ids: [...todosIds],
      };

      if (idsNaoSelecionados.length > 0) {
        await serviceSignature
          .postID(data)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            notify("erro", this.$t('combos.texts.txt26'));
            this.loading = false;
          });
      }
    },
    customLabelActionMail({ title }) {
      return `${title}`;
    },
    editAssinatura(data) {
      this.editMode = true;
      this.idToEdit = data.id;
      this.nameSignature = data.title;
      this.getCoursesToAssing(data.id);
      this.selectedStatus = data.status;
      this.$root.$emit("bv::show::modal", "modal-new-signature", "#btnShow");
    },
    async getCoursesToAssing(id) {
      await serviceSignature
        .read(id + "/course")
        .then((resp) => {
          this.actionCoursesSelected = resp;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openModalNew() {
      this.editMode = false;
      this.nameSignature = "";
      this.idToEdit = null;
      this.actionCoursesSelected = [];
      this.$root.$emit("bv::show::modal", "modal-new-signature", "#btnShow");
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    toPage(action) {
      if (action === "prev") {
        if (this.pageActual !== 1) {
          this.pageActual = this.pageActual - 1;
        }
      } else {
        if (this.lastPage > this.pageActual) {
          this.pageActual = this.pageActual + 1;
        }
      }
      this.getAllCombos();
    },
    deleteSignature(id) {
      this.loading = true;

      serviceSignature
        .destroy(id)
        .then(() => {
          this.getAllCombos();
          notify("sucesso", this.$t('combos.texts.txt27'));
          this.loading = false;
        })
        .catch(() => {
          notify("erro", this.$t('combos.texts.txt28'));
          this.loading = false;
        });
    },
    async getAllCourses(action) {
      await serviceCourse
        .search("page=" + this.pageCourse)
        .then((resp) => {
          if (action === "push") {
            let list = resp.data;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              this.allCourses.push(element);
            }
          } else {
            this.allCourses = resp.data;
          }
          this.pageCourse = resp.current_page;
          this.lastPageCourse = resp.last_page;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async getAllCombos() {
      this.loading = true;

      if (this.pageActual === null) {
        this.pageActual = 1;
      }

      var url = "";
      if (this.searchComment === "" || this.searchComment === null) {
        url = "page=" + this.pageActual;
      } else {
        url =
          "page=" + this.pageActual + "&search_string=" + this.searchComment;
      }

      await serviceSignature
        .search(url)
        .then((resp) => {
          var data = resp.data;
          this.pageActual = resp.current_page;
          this.lastPage = resp.last_page;
          this.allCombos = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAllCombos();
    this.getAllCourses();
  },
};
</script>
